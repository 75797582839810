<template>
  <b-container class="container-content">
    <div class="page-header">
      <h4 class="page-header-text">{{$t('terms.terms_and_conditions')}}</h4>
    </div>
    <TermsContent :hideHeader="true" />
  </b-container>
</template>

<script>
import TermsContent from '../components/Modal/TermsAndConditionContent'
export default {
  components: { TermsContent }
}
</script>
